import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-recruit.png'
import Page from '@/components/modules/Recruit/Entry/Page'
import Entry from '@/components/modules/Recruit/Entry'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import LinkButton1 from '@/components/common/LinkButton1'

const RecruitWebDirectorIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const company = data.company.edges[0].node
      const offices = data.offices.edges.map(el => el.node)
      const jobPosting = {
        '@type': 'JobPosting',
        ...data.jobPosting.edges[0].node.frontmatter.jobPostingProps,
        description: data.jobPosting.edges[0].node.html.replace(/\r?\n/g, ''),
        'jobLocation': offices.map(office => ({
          '@type': 'Place',
          'address': office.addressFragments
        })),
        'hiringOrganization': {
          ...LDEntity.Organization(siteMeta, company),
          sameAs: siteMeta.siteUrl
        }
      }
      const page = {
        blocks: data.blocks.edges.map(el => ({
          html: el.node.html,
          ...el.node.frontmatter
        })),
        employment: data.employment.edges.map(el => el.node)
      }
      return (
        <Root>
          <SEO location={props.location} title="募集要項：WEBディレクター | 採用情報" description="株式会社フロントビジョンのWEBディレクター募集要項です。"/>
          <LD json={jobPosting}/>
          <Head>
            <PageTitle src={imgTitle}>募集要項：WEBディレクター</PageTitle>
          </Head>
          <Main>
            <Row css={styles.Page}><Page {...page}/></Row>
            <Row css={styles.Entry}><Entry /></Row>
            <Row css={styles.Link}><Button to="/recruit/" component={Link} scale={800}>採用トップページ</Button></Row>
            <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
            <Row css={styles.Contact}><ContactLead /></Row>
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const styles = {
  Page: css`
    padding-bottom: 0;
    @media ${mq.and(mobile)} {
      padding-bottom: 0;
    }
  `,
  Entry: css`
    padding-bottom: 0;
    @media ${mq.and(mobile)} {
      padding-bottom: 0;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Link: css`
    text-align: center;
  `
}

export default PageBase(RecruitWebDirectorIndexPage)

export const query = graphql`
  query RecruitWebDirectorIndexPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
      }
    }
    company: allCompanyInfoYaml {
      edges {
        node {
          name
          legalName
          telephone
          email
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
    blocks: allMarkdownRemark (
      filter: { frontmatter: { postType: { eq: "web-director-block" } } }
      sort: { fields: [frontmatter___number], order: ASC }
    ) {
      edges {
        node {
          html
          frontmatter {
            postType
            number
            heading {
              sub
              main
            }
          }
        }
      }
    }
    employment: allRecruitWebDirectorEmploymentYaml {
      edges {
        node {
          key
          value
        }
      }
    }
    offices: allCompanyOfficeListYaml {
      edges {
        node {
          addressFragments {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
    jobPosting: allMarkdownRemark (
      filter: { frontmatter: { postType: { eq: "web-director-job-posting" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            jobPostingProps {
              name
              title
              datePosted
              employmentType
            }
          }
        }
      }
    }
  }
`
